import React, { useEffect, useState } from 'react'
import '../css/home.css'
import Excel from '../assets/excel.svg'
import Pdf from '../assets/pdf.svg'
import News from '../assets/news.svg'
import Calendar from '../assets/calendar.svg'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import axios from 'axios'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MailIcon from '@mui/icons-material/Mail'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'

export const Home = () => {
  const [docs, setDocs] = useState([])
  const pdfDocs = docs.some(({ description }) => description === 'pdf')
  const [tip, setTip] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const getTitle = (item) => {
    if (item.title === 'Api guide') return t('api_guide')
    if (item.title === 'Estonian construction material emissions data(ENG)') return t('estonian_construction_materials_emissions_data_eng')
    if (item.title === 'Estonian construction material emissions data(EST)') return t('estonian_construction_materials_emissions_data_est')
    if (item.title === 'Conversion factors for organisations') return `${t('conversion_factors_organizations')} ${item.year}`
    if (item.title === 'BOM Revit Template(ENG)') return t('revit_bom')
    if (item.title === 'BOM ArchiCAD template(ENG)') return t('archicad-bom')
    if (item.title === 'Estonian_construction_materials_emission_data-method report') return t('estonian_buildings_method_report')
    if (item.title === 'Estonian_emission_factors_for_organizations-method report') return t('estonian_organization_method_report')
    if (item.title === 'Estonian EPDs') return t('estonian_epds')
  }
  const navigateReports = () => {
    navigate('/reports', { replace: true })
  }

  const navigateNewsUpdates = () => {
    navigate('/news', { replace: true })
  }

  const getDocs = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/asset/documents`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setDocs(response.data)
          setTip(true)
          // eslint-disable-next-line no-console
          console.log(docs)
          console.log(tip)
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error!', error.message)
      })
  }

  useEffect(() => {
    getDocs()
  }, [])

  return (
    <Box className="box_home" sx={{ flexGrow: 0.5 }}>
      <article className="home_article">
        <section className="section_left">
          <h2>EHEA</h2>
          <br/>
          <h3>{t('ehea')}</h3>
          <p>{t('ehea_description')}</p>
          <div className="link-list">
          {docs.length !== 0 && docs.some(item => item.description !== 'pdf')
            ? (docs.map((item, index) => (
                item.description !== 'pdf' && (
                  <a
                    key={index}
                    className="link-item"
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img id="leftcol" src={Excel} alt="excel"/>
                    <span id="rightcol">
                      <strong>{getTitle(item)}</strong>
                    </span>
                  </a>
                ))))
            : (
              <div className="tip-wrapper">
                <b>{t('no_document')}{' '}</b>
              </div>
              )}
          </div>
        </section>

        <section className="section_right">
          <div className="link-list-right">
            <Divider textAlign="left">
              <Chip
                label={t('ghg_tools')}
                size="small"
                variant="outlined"
                color="info"/>
            </Divider>
            <a
              className="link-item"
              href="https://www.ghg.ee/"
              target="_blank"
              rel="noreferrer"
            >
          <span>
            <strong>{t('ghg_home')}</strong>
          </span>
            </a>
            <a
              className="link-item"
              href="https://ghg-tool.vercel.app/login?callbackUrl=https%3A%2F%2Fghg-tool.vercel.app%2F"
              target="_blank"
              rel="noreferrer"
            >
          <span>
            <strong>{t('okas_tool')}</strong>
          </span>
            </a>
            <a
              className="link-item"
              href="https://a-c-c-1.onrender.com/dashboard/home"
              target="_blank"
              rel="noreferrer"
            >
          <span>
            <strong>{t('sarv_tool')}</strong>
          </span>
            </a>
            <a
              className="link-item"
              href="https://data.ghg.ee/asset/api/docs/"
              target="_blank"
              rel="noreferrer"
            >
          <span>
            <strong>{t('api_guide')}</strong>
          </span>
            </a>
            <Divider textAlign="left">
              <Chip label={t('reports')} size="small" variant="outlined" color="info"/>
            </Divider>
                  {pdfDocs
                    ? (
                  <a className="link-item"
                     onClick={navigateReports}>
                    <img className="right-icon" src={Pdf} alt="pdf"/>
                    <span>
                   <strong>{t('view_reports')}</strong>
                    </span>
                  </a>
                      )
                    : (
                <a className="link-item">
                  <img className="right-icon" src={Pdf} alt="pdf"/>
                  <span>
                  <strong>{t('no_reports')}</strong>
                </span>
                </a>
                      )}
            <Divider textAlign="left">
              <Chip
                label={t('webinars_trainings')}
                size="small"
                variant="outlined"
                color="info"
              />
            </Divider>
            <a
              className="link-item"
              href="https://tinyurl.com/4f6tmv55"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              <img className="right-icon" src={Calendar} alt={t('calendar')}/>
              <span>
            <strong>{t('calendar')}</strong>
          </span>
            </a>
            <Divider textAlign="left">
              <Chip
                label={t('News and updates')}
                size="small"
                variant="outlined"
                color="info"
              />
            </Divider>
            <a className="link-item" onClick={navigateNewsUpdates}>
              <img className="right-icon" src={News} alt={t('whats_new')}/>
              <span><strong>{t('whats_new')}</strong></span>
            </a>
          </div>
        </section>
      </article>
      <footer className="footer_home">
        <Grid container justifyContent="space-between" p={2}>
          <Grid item>
            <Box display="flex" flexDirection="column" alignItems="center"></Box>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center" sx={{ pb: 2 }}>
              <Box display="flex" alignItems="center">
                <MailIcon sx={{ color: 'white', mr: 2 }}/>
                <a href="mailto:info@ghg.ee" style={{ color: 'white', textDecoration: 'none' }}>
                  <Typography variant="body1" sx={{ color: 'white', mb: 0.5, pr: 20 }}>
                    info@ghg.ee
                  </Typography>
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </footer>
    </Box>
  )
}
