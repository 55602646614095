import React, { useEffect, useRef, useState } from 'react'
import { Box, ThemeProvider } from '@mui/material'
import '../css/App.css'
import { Breadcrumbs } from './Breadcrumbs'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { theme } from './AppUtil'
import Pdf from '../assets/pdf.svg'

export const Reports = () => {
  const [reports, setReports] = useState([])
  const dtReport = useRef(null)
  const [selectedReports, setSelectedReports] = useState(null)
  const [globalReportFilter, setGlobalReportFilter] =
    useState(null)
  const { t } = useTranslation()

  const getReportTitle = (rowData) => {
    if (rowData.title === 'Background Report1_Steel and metals') return t('report1_steel_metals')
    if (rowData.title === 'Background Report2_Concrete products') return t('report2_concrete_products')
    if (rowData.title === 'Background Report3_Wood based') return t('report3_wood_based')
    if (rowData.title === 'Background Report4_infill materials and natural stone products') return t('report4_infill_materials_natural_stone_prdts')
    if (rowData.title === 'Background report5_other products') return t('report5_other_products')
  }

  const getPDFs = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/asset/documents`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          const pdfDocs = response.data.filter(document => document.description === 'pdf')
          setReports(pdfDocs)
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error!', error.message)
      })
  }

  useEffect(() => {
    getPDFs()
  }, [])

  const headerReport = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className="flex flex-wrap gap-2">
        <span>{t('reports')}</span>
      </div>
      <TextField
        type="search"
        placeholder="Search..."
        value={globalReportFilter}
        onInput={(e) => setGlobalReportFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginLeft: '8px' }}/>
            </InputAdornment>
          )
        }}
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  )

  return (
    <ThemeProvider theme={theme}> <Breadcrumbs/>
      <Box sx={{
        padding: 2,
        backgroundColor: 'bbox.main',
        color: 'secondary.main',
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto'
      }}>
        <Box>
          <Container maxWidth="lg" sx={{ mt: 1, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper
                  sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                >
                  <article>
                          <DataTable
                            ref={dtReport}
                            value={reports}
                            selection={selectedReports}
                            onSelectionChange={(e) => setSelectedReports(e.value)}
                            dataKey="id"
                            paginator
                            stripedRows
                            size={'small'}
                            rows={5}
                            editMode="row"
                            sortMode="multiple"
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={globalReportFilter}
                            header={headerReport}
                          >
                            <Column exportable={true}></Column>
                            <Column
                              field="year"
                              header={t('year')}
                              style={{ minWidth: '4rem' }}
                            ></Column>
                            <Column
                              field="title"
                              header= {t('title')}
                              body={getReportTitle}
                              style={{ minWidth: '12rem' }}
                            ></Column>
                            <Column
                              field="description"
                              header={t('description')}
                              body={() => (
                                <img className="pdf-icon" id="leftcol" src={Pdf} alt="pdf file"/>
                              )}
                              style={{ minWidth: '8rem' }}
                            ></Column>
                            <Column
                              field="link"
                              header={t('link')}
                              body={(rowData) => (
                                <a href={rowData.link} target="_blank" rel="noopener noreferrer" download>
                                  {t('download_doc')}
                                </a>
                              )}
                              style={{ minWidth: '13rem' }}
                            ></Column>
                          </DataTable>
                  </article>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
